import React, { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";

const App = () => {
  const urlSearchString = window.location.search;

  const params = new URLSearchParams(urlSearchString);
  const androidPlayStoreUrl =
    "https://play.google.com/store/apps/details?id=com.docaposte.GIP";
  const iosPlayStoreUrl = "https://apps.apple.com/us/app/instagram/id389801252";

  useEffect(() => {
    console.log(isIOS);
    console.log(isAndroid);

    if (isAndroid) {
      // const url = `intent://qrcode?context=${params.get(
      //   "context"
      // )}#Intent;scheme=gip;package=com.docaposte.gip;end;`;

      const url = `gip://qrcode?context=${
        window.location.origin
      }/${window.location.search.substring(1)}`;
      window.location.replace(url);

      setTimeout(() => {
        window.location.replace(androidPlayStoreUrl);
      }, 1000);
    } else if (isIOS) {
      window.location.replace(`GIP://qrcode?${params.get("context")}`);

      setTimeout(() => {
        window.location.replace(iosPlayStoreUrl);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mx-auto py-40">
      <div className="flex justify-evenly">
        {isAndroid ? (
          <div>
            <div>
              Si vous n'êtes pas redirigé automatiquement, veuillez cliquer su
              le lien :
            </div>
            <a href={androidPlayStoreUrl}>Open Android app</a>
          </div>
        ) : isIOS ? (
          <div>
            <div>
              Si vous n'êtes pas redirigé automatiquement, veuillez cliquer su
              le lien :
            </div>
            <a href={iosPlayStoreUrl}>Open iOS app</a>
          </div>
        ) : (
          <>
            <div className="grid place-items-center">
              <img
                style={{ width: "225px" }}
                src="/logo-ir.jpg"
                alt="mon certificat de vie logo"
              ></img>
              <h1 className="text-6xl">Mon certificat de vie</h1>
              <p className="text-xl">
                Vous devez télécharger l'application "Mon certificat de vie" sur
                votre smartphone pour accéder au service.
              </p>
              <div className="grid grid-cols-2 place-items-center">
                <a href="http://play.google.com">
                  <img
                    style={{ width: "189px" }}
                    src="/badges/google/FR/badge.png"
                    alt="android store badge"
                  ></img>
                </a>
                <a href="https://www.apple.com/fr/app-store/">
                  <img
                    style={{ width: "178px" }}
                    src="/badges/apple/FR/badge.svg"
                    alt="ios store badge"
                  ></img>
                </a>
              </div>
            </div>
            <div className="image">
              <img src="/app-mobile-mon-certificat-de-vie.png" alt="" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default App;
